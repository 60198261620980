<template>
    <div class="page-container app-h100" style="opacity:0">
        <h1>placeholder</h1>
        <input type="text" ref="input" />
    </div>
</template>

<script>
    import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';

    export default {
        name: "placeholder",
        components: {

        },
        data() {
            return {
                prevUrl: this.$route.query.prevUrl ? this.$route.query.prevUrl : ''
            }
        },
        created () {

        },
        mounted() {
            this.changeMenu({
                index: 0,
                indexPath: ['0']
            });
            this.$refs.input.focus();
            history.go(-1);
        },
        computed: {

        },
        methods: {
            ...mapMutations(['changeMenu'])
        }
    }
</script>

<style scoped>

</style>